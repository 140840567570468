const time = 100;
const step = 10;

const validate = Array.from(document.getElementsByClassName('validate'))
const from = document.getElementById('from')
const to = document.getElementById('to')
console.log('name: ', new Date().toISOString())
from.value = new Date().toISOString().slice(0, 16)
from.min = new Date().toISOString().slice(0, 16)
to.value = new Date(Date.now() + 48 * 60 * 60 * 1000).toISOString().slice(0, 16)
to.min = new Date(Date.now() + 48 * 60 * 60 * 1000).toISOString().slice(0, 16)

function outNum(num, elem) {
    let e = document.querySelector("#out");
    let n = 0;
    let t = Math.round(time / (num / step));
    let interval = setInterval(() => {
        n = n + step;
        if (n == num) {
            clearInterval(interval);
        }
        e.innerHTML = n + " ₽";
    }, t);
}

const all = document.querySelectorAll('input[type=datetime-local]')

all.forEach(item => {
    item.addEventListener("change", (item) => {
        const partsStart =  new Date(from.value)
        const partsEnd = new Date(to.value)
        if (Math.floor((partsEnd - partsStart) / (1000 * 60 * 60 * 24)) > 1) {
            outNum(200 * (Math.floor((partsEnd - partsStart) / (1000 * 60 * 60 * 24)) + 1), '#out')
        }
        else  {
            document.querySelector("#out").innerHTML = "0 ₽"
        }

        validate.forEach(el => {
            el.innerHTML = ''
        })
        document.getElementsByClassName('form__item-one')[0].classList.remove('custom-input--warning')
        document.getElementsByClassName('form__item-two')[0].classList.remove('custom-input--warning')
        document.getElementsByClassName('form__item-three')[0].classList.remove('custom-input--warning')
        document.getElementsByClassName('form__item-four')[0].classList.remove('custom-input--warning')
    } )
})


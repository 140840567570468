//---Modal component---
const modal = document.getElementById("myModal");
const [modalContent] = document.getElementsByClassName("modal-content");
const [map] = document.getElementsByClassName("map-wrapper");
const [img] = document.getElementsByClassName("modal__img");
let [confirmed] = document.getElementsByClassName("modal__confirmed")
const images = Array.from(document.getElementsByClassName("gallery__item"));

images.forEach(item => {
    item.onclick = function () {
        map.style.display = "none"
        modal.style.visibility = "visible";
        modalContent.classList.add('modal-content--img');
        img.style.display = "block";
        img.src = item.querySelector('.gallery__image').src
    }
})

// Get the button that opens the modal
const btn = document.getElementById("map");

// Get the <span> element that closes the modal
const span = document.getElementsByClassName("close")[0];

// When the user clicks the button, open the modal
btn.onclick = function () {
    modal.style.visibility = "visible";
    map.style.display = "block"
};

// When the user clicks on <span> (x), close the modal
span.onclick = function () {
    modal.style.visibility = "hidden";
    map.style.display = "none"
    img.style.display = "none";
    confirmed.style.display = "none";
    modalContent.classList.remove('modal-content--img');
    modalContent.classList.remove('confirmed-modal')
};

// When the user clicks anywhere outside of the modal, close it
window.onclick = function (event) {
    if (event.target == modal) {
        modal.style.visibility = "hidden";
        map.style.display = "none"
        img.style.display = "none";
        confirmed.style.display = "none";
        modalContent.classList.remove('modal-content--img');
        modalContent.classList.remove('confirmed-modal')
    }
}
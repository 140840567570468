//---Scroll---
document
    .querySelectorAll('.anchor-form a[href^="#"]')
    .forEach(trigger => {
        trigger.onclick = function(e) {
            e.preventDefault();
            let hash = this.getAttribute('href');
            let target = document.querySelector(hash);
            let elementPosition = target.offsetTop;

            window.scrollTo({
                top: elementPosition,
                behavior: "smooth"
            });
        };
    });
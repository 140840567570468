const leftSlide = document.getElementById("leftSlide");
const rightSlide = document.getElementById("rightSlide");
const container = document.getElementsByClassName('gallery__slider-wrapper')[0]

leftSlide.onclick = function () {
    container.scrollBy({left: -360, behavior: 'smooth'})
};

rightSlide.onclick = function () {
    container.scrollBy({left: 360, behavior: 'smooth'})
};